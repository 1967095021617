import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const NonDisclosure = () => {

    const style = {
        maxWidth: "800px",
        margin: "88px auto 40px auto",
        padding: "0 40px"
    };

    return (
        <SharedStateProvider>
            <Layout>
                <SEO 
                    title="Non-Discrimination Disclosure - Michigan Oral Surgeons" 
                    description="Visit this page to read about our non-discrimination disclosure at our oral surgery practice." 
                />
                <h1 id="SEOTitle">Non-Discrimination Disclosure</h1>
                <div className="joshua-tree-content" style={style}>
                    <ImageMeta
                        cloudName="nuvolum"
                        publicId="MOS/DEV/non-discrimination-disclosure"
                        width="auto"
                    />

                    <br />

                    <ImageMeta
                        cloudName="nuvolum"
                        publicId="MOS/DEV/non-discrimination-disclosure-2"
                        width="auto"
                    />
                </div>

            </Layout>
        </SharedStateProvider>
    )
}

export default NonDisclosure